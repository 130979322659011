
.table {
  :global {
    .ant-table {
      background: unset;
    }

    tbody.ant-table-tbody > tr.ant-table-row > td {
      padding: 0;
      border: none;
    }
    tbody.ant-table-tbody > tr.ant-table-row:hover > td {
      background: unset;
    }
  }
}
