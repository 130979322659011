@import "~@dewo/app/styles/theme.less";

.listItem {
  padding-left: 16px;
  padding-right: 16px;
  row-gap: 8px;
  column-gap: 16px;

  transition: background-color 0.3s ease-in-out;

  :global {
    @media (min-width: @screen-sm) {
      .ant-progress {
        width: 200px;
      }
  
      .ant-typography {
        flex: 1;
      }
    }
  }


  &:hover {
    background: @body-background-secondary;
  }
}
