@import "~@dewo/app/styles/theme.less";

.listItem {
  padding: 12px 16px;
  border-bottom: 1px solid @divider-color;
  cursor: pointer;

  background: transparent;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background: @body-background-secondary;
  }
}
